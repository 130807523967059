import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAsCAxSeBpSFJ9WNmHhuasDsoB9J-2YTMI",
  authDomain: "archeus.seelay.in",
  projectId: "archeus-app",
  storageBucket: "archeus-app.appspot.com",
  messagingSenderId: "514756246755",
  appId: "1:514756246755:web:b38faf2c87b185e5fe19d6",
  measurementId: "G-X2NWVN8470",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

// firebase.firestore().enablePersistence()

export { firebase, db };
