import { Computer } from "../components/Icons";
import React, { useEffect, useState } from "react";
import { osName, CustomView } from "react-device-detect";

export default function Installweb() {
  // let comp;
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    // const UA = navigator.userAgent;

    // const IOS = UA.match(/iPhone|iPad|iPod/);
    // const ANDROID = UA.match(/Android/);

    // const PLATFORM = IOS ? "ios" : ANDROID ? "android" : "unknown";

    // const standalone = window.matchMedia("(display-mode: standalone)").matches;

    // if (standalone || (IOS && !UA.match(/Safari/))) {
    //   let promowindows = document.getElementsByClassName(
    //     "pb-3.5 pt-3.5 px-2 py-1 -ml-1 mr-5 bg-blue-600 text-white hover:bg-red-400 hover:text-blue-50  rounded-sm  transform hover:scale-105 transition-all duration-300 invisible md:visible"
    //   );
    //   promowindows[0].style.display = "none";

    //   //fixed ios
    // } else if ((!ANDROID && standalone) || (IOS && UA.match(/Safari/))) {
    //   let promowindows = document.getElementsByClassName(
    //     "pb-3.5 pt-3.5 px-2 py-1 -ml-1 mr-5 bg-blue-600 text-white hover:bg-red-400 hover:text-blue-50  rounded-sm  transform hover:scale-105 transition-all duration-300 invisible md:visible"
    //   );
    //   promowindows[0].style.display = "none";

    //   //fixed ios
    // } else if (ANDROID || (!standalone && !IOS && !UA.match(/Safari/))) {
    //   let promowindows = document.getElementsByClassName(
    //     "pb-3.5 pt-3.5 px-2 py-1 -ml-1 mr-5 bg-blue-600 text-white hover:bg-red-400 hover:text-blue-50  rounded-sm  transform hover:scale-105 transition-all duration-300 invisible md:visible"
    //   );
    //   promowindows[0].style.display = "none";
    // } else {
    // }

    const handler = (e) => {
      // e.preventDefault();
      // console.log("Install App ?");

      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    // evt.preventDefault();
    // if (!promptInstall) {
    //   return;
    // }
    promptInstall.prompt();
  };
  // if (!window.matchMedia("(display-mode: standalone)").matches) {
  //   comp = (
  //     <button
  //       className="pb-3.5 pt-3.5 px-2 py-1 -ml-1 mr-5 bg-blue-600 text-white hover:bg-red-400 hover:text-blue-50  rounded-sm  transform hover:scale-105 transition-all duration-300 invisible md:visible"
  //       onClick={onClick}
  //     >
  //       <span className="flex">
  //         <Computer /> &nbsp;Install App{" "}
  //       </span>
  //     </button>
  //   );
  // }

  return (
    <CustomView
      condition={
        osName === "Windows" &&
        !window.matchMedia("(display-mode: standalone)").matches
      }
    >
      <button
        className="pb-3.5 pt-3.5 px-2 py-1 -ml-1 mr-5 bg-blue-600 text-white hover:bg-red-400 hover:text-blue-50  rounded-sm  transform hover:scale-105 transition-all duration-300 "
        onClick={onClick}
      >
        <span className="flex">
          <Computer /> &nbsp;Install Windows App{" "}
        </span>
      </button>
    </CustomView>
  );
}
